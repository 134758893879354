<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-space-between class="mb-6">
      <v-flex xs6 sm4 md4 lg4>
        <h2 class="headline text-capitalize">{{ $t("new overtime") }}</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 md4 lg4 class="text-right">
        <v-btn
          small
          dark
          :loading="loading"
          :color="$store.state.secondaryColor"
          @click="uploadData"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize">{{ $t("submit") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-alert>
      <v-form ref="form">
        <v-layout row wrap class="caption mt-1">
          <v-flex xs12 sm6 md6 lg6 class="px-1">
            <span class="font-weight-medium text-capitalize">{{
              $t("overtime hours")
            }}</span>
            <v-text-field
              outlined
              dense
              v-model="overtime.overtimeHours"
              :rules="rules.emptyField"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md6 lg6 class="px-1">
            <span class="font-weight-medium text-capitalize">{{
              $t("overtime date")
            }}</span>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="overtime.overtimeDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="overtime.overtimeDate"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="overtime.overtimeDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(overtime.overtimeDate)"
                >
                  {{ $t("ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-form>
    </v-alert>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("overtime added successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("could not add a overtime. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
export default {
  data: () => ({
    snackSuccess: false,
    snackError: false,
    loading: false,
    modal: false,
    payroll: [],
    timeout: 2000,
    ratePerHour: "",
    overtime: {
      businessId: "",
      employeeId: "",
      overtimeDate: "",
      overtimeHours: "",
      names: {},
    },
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },

    countries() {
      return this.countryList.map((item) => item.country);
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || "Please enter a valid email",
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  created() {
    this.getEmployeeDetails();
  },
  methods: {
    goBack() {
      window.history.back();
    },
    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();
          this.loading = false;

          this.overtime.employeeId = this.employees.employeeId;
          this.overtime.businessId = this.employees.businessId;
          this.overtime.names.first = this.employees.names.first;
          this.overtime.names.middle = this.employees.names.middle;
          this.overtime.names.last = this.employees.names.last;

          this.getBasicPay();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getBasicPay() {
      console.log("get basic pay");
      db.collection("payroll")
        .where("employeeId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.payroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.basicPay = this.payroll[0].basicPay;
          this.ratePerHour = this.basicPay / 24;
        });
    },
    uploadData() {
      this.overtime.overtimeDate = new Date(this.overtime.overtimeDate);

      this.overtime.overtimePay =
        Math.round(this.ratePerHour) * this.overtime.overtimeHours;
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("overtimes")
          .add(this.overtime)
          .then(() => {
            this.loading = false;
            this.snackSuccess = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch(() => {
            this.snackError = true;
          });
      }
    },
  },
};
</script>

<style></style>
